.PortfoliosCard {
    --badge-min-w: 106px;
    --card-line-spacing: 20px;
    --details-btn-w: 60px;
    --details-btn-p: 3px 11px;
    --action-btn-w: 30px;
    --action-btn-p: 4px;
    --action-btn-ml: 10px;
    --icon-size: 16px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.portfolios-card-name .Title {
    text-decoration: underline;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.portfolios-card-name .Badge {
    margin-top: 0;
}

.portfolios-card-profile {
    color: var(--secondary-default);
    display: flex;
}

.portfolios-card-profile .profile-title,
.portfolios-card-profile .product-title {
    color: var(--table-label-grey);
}

.portfolios-card-profile .portfolios-card-profile-cell,
.portfolios-card-profile .portfolios-card-profile-cell {
    color: #000000;
    font-weight: 700;
}

.portfolios-card-profile > div {
    margin-right: calc(var(--padding-base) / 2);
}

.portfolios-card-name,
.portfolios-card-status,
.portfolios-card-profile {
    justify-self: self-start;
    align-items: center;
}

.portfolios-card-value,
.portfolios-card-performance {
    justify-self: self-end;
}

.portfolios-card-performance,
.portfolios-card-value h2.Title {
    font-weight: bold;
    text-align: right;

}

.portfolios-card-performance {
    font-size: var(--font-size-base);
}

.portfolios-card-performance .table-cell {
    font-size: calc(var(--font-size-base) + 2px);
}

.portfolios-card-performance .table-cell_positive {
    color: var(--positive-default);
}

.portfolios-card-performance .table-cell_negative {
    color: var(--negative-default);
}

.profile-icon {
    color: var(--primary-default);
    margin-left: 5px;
}

.profile-icon .Icon {
    cursor: auto;
}

.profile-icon svg {
    width: var(--icon-size);
    height: var(--icon-size);
}

.portfolios-card-status {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-top: calc(var(--margin-base) / 2);
}

.PortfoliosCard-disabled {
    pointer-events: none;
}

.portfolios-card-details {
    grid-column: 1 / 4;
    display: flex;
    justify-content: space-between;
    padding-top: var(--card-line-spacing);
    border-top: var(--border-base);
}

.portfolios-card-details-btn {
    min-width: var(--details-btn-w);
    padding: var(--details-btn-p);
    border: 1px solid #003466;
    border-radius: 17px;
    width: 82px;
    color: #003466;
}

.portfolios-card-actions {
    display: flex;
    justify-content: flex-end;
}

.portfolios-card-action-btn {
    min-width: var(--action-btn-w);
    padding: var(--action-btn-p);
    margin-left: var(--action-btn-ml);
}

.portfolios-card-action-btn .Icon {
    padding: calc(var(--dropdown-p) - 8px);
}

@media (max-width: 450px) {
    .PortfoliosCard {
        display: block;
    }

    .portfolios-card-name {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .portfolios-card-performance,
    .portfolios-card-value h2.Title {
        text-align: left;
        margin-bottom: 0;
    }

    .portfolios-card-name .Title {
        width: 45%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .portfolios-card-actions {
        margin-top: var(--margin-base);
    }

    .portfolios-card-profile {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .portfolios-card-profile .product-title {
        margin-left: 0;
    }
}

@media (max-width: 350px) {
    .PortfoliosCard {
        display: block;
    }

    .portfolios-card-name .Title {
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
