@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'),
        url('/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
}

#root {
    background: #F5F5F5;
}

.Header__navigation .Navigation__link {
    padding: 20px;
}

.container .Header__left,
.container .Header__right {
    min-width: unset !important;
}

.container .Header__logo {
    margin-left: 35px;
}

.Header__navigation a.Navigation__link-active.active {
    color: var(--active-orange) ! important;
    border-bottom: 3px solid var(--active-orange) ! important;
}

.Navigation a.Navigation__link-active {
    color: var(--active-orange) ! important;
    border-bottom: 3px solid var(--active-orange) ! important;
    margin-right: 30px;
}

header .container {
    background-color: #fff;
    box-shadow: 0 2px 4px 2px rgba(212, 212, 211, 0.5);
    max-width: var(--container-max-width);
    height: 60px !important;
}

.news i.Icon-arrow,
h2.Title i.Icon-arrow {
    color: var(--active-orange) !important;
    width: 20px;
    height: 15px;
    font-size: 16px !important;
}

.profile-menu .Dropdown-container .Avatar.avatar-image,
.profile-menu .Dropdown-container .notifications-menubutton,
.profile-menu__content .Avatar.avatar-image {
    border-radius: 15px;
    background-color: #fff;
    border: 1px solid #DBDCDC;
}

.profile-menu .Dropdown-container .Avatar.avatar-image img,
.profile-menu .Dropdown-container .notifications-menubutton img,
.profile-menu__content .Avatar.avatar-image img {
    transform: scale(0.8);
}

.profile-menu .Dropdown-container .notifications-menubutton svg {
    fill: #7e7d7d;
}

/* Dashboard */

section .news h2.Title,
.portfolios-list h2.Title {
    text-transform: uppercase;
}

.Content-box .overview__header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
}

.dashboard .overview-widget-container {
    background-color: #003466;
}

.dashboard .overview-widget-container h2 {
    color: #fff !important;
}

.dashboard .overview-item {
    flex-direction: row;
    justify-content: space-between;
}

.dashboard .overview-panel {
    padding: 0 20px;
}

.dashboard .overview-item__title,
.dashboard .overview-item__currency {
    color: #f8f9fe !important;
    font-weight: 400 !important;
    font-size: 30px;
}

.dashboard .overview-item__title {
    margin-top: 4px;
}

.dashboard .overview-item__value {
    color: #f8f9fe !important;
    font-weight: 700 !important;
    font-size: 30px;
    line-height: 29px;
}

.news .load-more button {
    border-radius: 100px;
    width: 400px;
}

.LanguageSwitcher  .select button {
    border-radius: 50px;
}

.Content-box.content-box_title h2.Title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 27px;
    line-height: 34px;
    color: var(--title-grey);
}

.Content-box.relationship-manager h2.Title,
.investment-allocation .Content-box h2.Title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: var(--title-grey);
}

.Content-box.relationship-manager h3.Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.media-links a {
    color: #000000;
}

.Content-box,
.dashboard .infobox-with_button {
    box-shadow: 0 2px 4px 2px rgba(212, 212, 211, 0.25);
    border-radius: 15px;
}

.Tabs-extra__item_active svg {
    fill: var(--active-orange);
}

/* Login page */
.Auth .login-button button {
    width: 190px !important;
    background: #163559;
    border-radius: 100px !important;
    margin-top: 48px;
}

.Auth .login-button {
    text-align: right !important;
}

.Auth .detail_content .content-pane .buttons.button-group .button-primary {
    margin-bottom: 0;
}

.Auth .forgot-button {
    color: #0000FF;
    text-decoration: none;
    margin-left: 10px;
}

.container .content-pane-wrapper {
    width: 392px;
    box-shadow: 0 0 8px 9px #ECEFFB !important;
    border-radius: 25px !important;
    padding: 50px !important;
}

.container .content-pane-wrapper .content-pane h2.Title {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    margin-bottom: 75px;
}

.container .content-pane-wrapper .content-pane .image-container {
    display: flex;
    justify-content: center;
    margin-bottom: var(--padding-base);
}

.container .content-pane-wrapper .content-pane .Form-control-label {
    display: none;
}

.container .content-pane-wrapper .content-pane .TextInput-wrapper {
    border-radius: 100px;
    width: 294px;
    height: 50px;
}

.container .content-pane-wrapper .content-pane .Passcode.passcode-inputs .TextInput-wrapper {
    border-radius: unset;
    width: unset;
}

.container .content-pane-wrapper .content-pane .TextInput-suffix {
    padding-right: 0;
}

.container .content-pane-wrapper .content-pane .Info-box .info-box__icon {
    display: none;
}

.container .content-pane-wrapper .content-pane .info-box_error {
    background-color: #fff;
    font-size: 14px;
    color: var(--error-red);
    border: none;
}

.container .content-pane-wrapper .content-pane .validation-summary-errors {
    margin-top: -76px;
    width: 100%;
}

.container .content-pane-wrapper .content-pane .validation-summary-errors .Info-box {
    padding-right: 0;
    padding-left: 0;
}

.container .content-pane-wrapper .content-pane .TextInput-affix-wrapper input {
    border-radius: 100px;
    height: 50px;
}

.container .content-pane-wrapper .content-pane .TextInput-affix-wrapper input:focus {
    outline: #0000FF;
    box-shadow: 0 0 10px #9ecaed;

}

.container .content-pane-wrapper .content-pane .TextInput-affix-wrapper input {
    width: 300px;
}

.container .content-pane-wrapper .content-pane .Passcode.passcode-inputs .TextInput-affix-wrapper input {
    width: 28px;
}

.container .content-pane-wrapper .content-pane .TextInput-wrapper .addon-after-border {
    margin-left: -50px;
    z-index: 100;
}

/* Tasks widget */
.dashboard .second-section .tasks .Table-content td {
    font-weight: 400;
    font-size: 18px;
}

.tasks-widget .table-cell_urgency {
    border-radius: 5px !important;
    height: 23px;
    font-size: 14px;
}

.tasks-widget tr td:last-child {
    padding-top: 3px !important;
}

.tasks-widget .dropdown-title-container span:nth-child(2) {
    font-weight: 700;
}
