.portfolios {
    margin-bottom: 40px;
}

.portfolios.Content-box {
    padding-bottom: var(--indent-base);
}

.portfolios .info-box_error {
    margin-bottom: var(--indent-base);
}

.portfolios .Loader-wrapper {
    padding-bottom: 120px !important;
}

.portfolios .PageHeader .Loader-wrapper {
    padding-bottom: 0 !important;
    min-width: 95px;
}

.portfolios .portfolios-table {
    margin: 0 calc(var(--padding-base) * -1);
}

.portfolios .Table-wrapper {
    padding: 0 calc(var(--indent-base) * 2);
    max-width: 100%;
}

.portfolios .Table thead > tr th:last-child .filter.select__multi-mini {
    margin-right: 0;
}

.action-logo {
    cursor: pointer;
}

.portfolios .Badge {
    margin-top: 0;
}

.portfolios .portfolios-table .ColumnFilter {
    display: none !important;
}

.portfolios.Content-box .PageHeader > :nth-child(2) {
    text-align: right;
}

@media only screen and (max-width: 768px) {
    .portfolios .Table-wrapper .fixed-last-column tbody td:last-child {
        margin-top: 8px;
    }
}

@media only screen and (max-width: 767px) {
    .portfolios .portfolios-table {
        padding: 10px;
    }
}
