.MTan .info-text {
    color: var(--secondary-default);
}

.MTan a {
    display: block;
    text-align: center;
    text-decoration: underline;
}

.MTan .form-control-message {
    margin-bottom: 0;
    display: inline-block;
}

.MTan .buttons.button-group .link {
    height: 24px;
    padding: 0;
    color: var(--secondary-default) !important;
}

.MTan .buttons .cancel-button {
    color: var(--secondary-default);
    font-weight: inherit;
    text-decoration: underline;
    margin-bottom: 0;
}

.MTan .buttons.button-group .link span {
    text-decoration: underline !important;
}

.MTan .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-right-color: transparent !important;
}

.MTan .passcode-inputs {
    margin-bottom: calc(var(--margin-base) + 4px);
}

.MTan .passcode-inputs .form-group {
    display: inline-block;
}

.MTan .passcode-inputs .form-group+.form-group {
    margin-left: 3px;
}

.MTan .passcode-inputs .ant-input-affix-wrapper:hover .ant-input {
    border-width: 0 0 1px !important;
}

.MTan .passcode-inputs input {
    width: 28px;
    font-size: 18px;
    padding: 0 5px !important;
    border-width: 0 0 1px !important;
    border-radius: 0 !important;
    text-align: center;
}

.MTan .passcode-inputs input:focus,
.MTan .passcode-inputs input:hover {
    outline: 0 !important;
    border-width: 0 0 1px !important;
}

.MTan.content-pane .validation-summary-errors {
    display: flex;
    justify-content: center;
}
