.media.atram {
    display: flex;
    align-items: flex-start;
    margin-top: -5px;
    color: #748091;
}

.media.atram .media-body {
    flex: 1;
    padding-bottom: 0;
}

.media-body .media-links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: calc(var(--indent-base) / 3 * 2);
}

.media-body .media-links a {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    margin-top: calc(var(--indent-base) / 3);
}

.media-body div.title {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
}

.media-body div.name {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #003466;
}

.media.atram .media-body:last-child {
    padding-left: 20px;
}

.media.atram .media-body:first-child {
    border-right: 1px solid #D9D9D9;
}

.media .picture {
    width: 72px;
    height: 72px;
    margin-right: 10px;
    border-radius: 50%;
}

.relationship-manager {
    min-height: 180px;
}
