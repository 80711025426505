#root .first-section .Loader-wrapper {
    padding-bottom: 10px !important;
    padding-top: 35px;
}

.base-margin-bottom {
    margin-bottom: 30px;
}

.row.display-flex {
    display: flex;
    flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.second-section .row {
    height: 100%;
}

.second-section .Content-box {
    height: 100%;
}

.container .dashboard .row {
    margin-bottom: 0 !important;
}

.dashboard .relationship-manager.Content-box {
    padding-bottom: 15px;
}

.dashboard .relationship-manager .info-box_error {
    margin-bottom: 15px;
}

.dashboard .profile {
    padding-bottom: 30px !important;
}

.dashboard .portfolios {
    margin-top: 0;
    margin-bottom: 0;
}

.dashboard .portfolios .Loader-wrapper {
    padding-top: 50px !important;
    padding-bottom: 120px !important;
}

.dashboard .news {
    margin: 0;
}

.dashboard .overview {
    padding: 30px;
    background-color: var(--white);
}

.dashboard .overview-item {
    padding-right: var(--margin-base);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding-bottom: 0;
    margin-top: 0 !important;
}

.dashboard .table-wrapper {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.dashboard .table-cell {
    vertical-align: middle;
    line-height: 0.875;
}

.dashboard .table-cell_linked {
    color: var(--primary-default);
    text-decoration: underline;
}

.dashboard .highcharts-pie.highcharts-pie_single-allocation .legend-item {
    margin-left: 5px;
}

.dashboard .asset-development .Tabs-content {
    padding-top: calc(var(--padding-base) / 2);
}

.dashboard .asset-development .Tabs-bar .Tabs-nav {
    min-width: 180px;
}

.dashboard .asset-development .LineChart {
    padding-top: 2px;
    margin-left: -10px;
    margin-bottom: -15px;
}

.dashboard .LineChart .highcharts-tooltip > span {
    padding: 11.5px 7px !important;
    white-space: nowrap !important;
}

.dashboard .new-proposal-infobox {
    margin-bottom: 30px;
}

.dashboard .Info-box {
    margin-bottom: var(--margin-base);
}

.dashboard .overview-panel .additional-data {
    width: 100%;
    padding-left: 0;
}

.dashboard .overview-panel .additional-data.valuation-date {
    color: #fff;
    font-size: 20px;
    margin-left: -18px;
}

.dashboard .overview-panel .value-container {
    display: flex;
    justify-content: flex-end;
}

.dashboard .overview-cash {
    margin-bottom: 0;
    font-size: 1.6rem;
}

.dashboard .overview-widget-container {
    padding-bottom: 40px;
}

@media (max-width: 767px) {
    .dashboard .ColPrimary,
    .dashboard .ColSecondary {
        margin-bottom: 1px;
    }

    .dashboard .portfolios-list {
        margin-top: var(--padding-base);
    }

    .dashboard .portfolios-list .Content-box {
        margin: 0;
        border-radius: var(--content-box-border-radius);
    }

    .dashboard .overview-panel {
        margin-bottom: calc(var(--indent-base) * -1);
    }

    .dashboard .asset-development .Tabs-extra__item:last-child {
        margin: 0;
        width: 100%;
    }

    .dashboard .investment-allocation .Tabs {
        height: auto;
    }

    .dashboard .overview-panel .additional-data {
        margin-top: calc(var(--margin-base) / -2);
        margin-bottom: calc(var(--margin-base) / 2);
    }
}

.dashboard .investment-allocation .PieChart .highcharts-pie {
    width: 100%;
    justify-content: center;
}

@media (max-width: 576px) {
    .welcome-notification {
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
        width: calc(100vw - 5 * var(--padding-base));
        max-width: 100vw;
    }
}
