.investment-allocation .Tabs-content {
    height: calc(100% - var(--tabs-card-height) - var(--padding-base) / 2);
}

.investment-allocation .Tabs-content .TabPane.TabPane-active {
    height: 100%;
}

.investment-allocation .Tabs-content .TabPane.TabPane-active .PieChart {
    height: 100%;
    justify-content: center;
}

.investment-allocation .Tabs-content .TabPane.TabPane-active .PieChart .legend {
    min-width: 240px;
}

.investment-allocation .Tabs-content .TabPane.TabPane-active.TabPane-bar {
    display: flex;
    align-items: center;
}

.investment-allocation .table-wrapper {
    margin-top: calc(-1 * var(--padding-base) / 2) !important;
    margin-bottom: calc(-1 * var(--indent-base)) !important;
}

.investment-allocation .single-tab {
    margin-top: -50px !important;
}

.investment-allocation .single-tab .Tabs-bar {
    border-bottom: none;
}
