.tasks {

    /* stylelint-disable */
    --task-yellow: #CEB55E;
    --task-green: #4C7684;

    /* stylelint-enable */

    position: relative;
}

.tasks .table .table-cell_date {
    white-space: nowrap;
}

.tasks .table .table-cell_urgency {
    display: inline-block;
    width: 64px;
    padding: 3px 0;
    color: var(--white);
    font-size: 1.3rem;
    line-height: 1.15384615;
    text-align: center;
    border-radius: 3px;
}

.tasks .table .table-cell_urgency-red {
    background-color: var(--negative-default);
}

.tasks .table .table-cell_urgency-yellow {
    background-color: var(--task-yellow);
}

.tasks .table .table-cell_urgency-green {
    background-color: var(--task-green);
}

.tasks .TasksSkeleton {
    display: grid;
    grid-template-columns: 6fr 1fr 1fr 1fr;
    margin: 0 30px;
    min-height: 70px;
    grid-gap: 5px;
    border-bottom: 1px solid var(--secondary-lighter);
    overflow-x: hidden;
}

.tasks .TasksSkeleton > div {
    align-self: center;
    justify-self: end;
}

.tasks .TasksSkeleton > div:first-child {
    justify-self: start;
}

.tasks .TasksSkeleton:first-child {
    border-top: 1px solid var(--secondary-lighter);
}

.tasks .TasksSkeleton:last-child {
    border-bottom: none;
}

.tasks .TasksSkeleton .resize-content > .TasksSkeleton:first-child {
    border-top: 1px solid var(--secondary-lighter);
}

.tasks .TasksSkeleton .resize-content > .TasksSkeleton:last-child {
    border-bottom: none;
}
